<template>
	<div>
		<el-form ref="saveOrEditForm" :model="saveData" style="width:800px; padding: 20px 0;" :rules="rules"
			label-width="160px">
			<el-form-item label="sn号" v-if="type == 'update'">
				{{ saveData.sn }}
			</el-form-item>
			<el-form-item label="连续SN号" v-if="type != 'update'">
				<el-input v-model="saveData.beginSn" style="width:160px"></el-input>
				-
				<el-input v-model="saveData.endSn" style="width:160px"></el-input>
			</el-form-item>
			<el-form-item label="sn号" v-if="type != 'update'">
				<FileUpload @change="getFile"></FileUpload>
			</el-form-item>
			<el-form-item label="终端状态" v-if="type == 'update'">
				{{ saveData.status | terminalStatusFM }}
			</el-form-item>
			<el-form-item label="机具型号" prop="hardwareNo">
				<el-select v-model="saveData.hardwareNo" placeholder="请选择机具型号" style="width: 240px"
					@change="hardwareChange">
					<el-option v-for="item in hardwareList" :key="item.id" :label="item.hardwareName"
						:value="item.hardwareNo"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="机具类型" prop="hardwareType">
				{{ saveData.hardwareType | hardwareTypeFM }}
			</el-form-item>
			<el-form-item label="代理/机构编号" prop="agentNo">
				<el-select style="width:240px" v-model="saveData.agentNo" filterable remote reserve-keyword
					placeholder="请输入代理商/机构编号" :remote-method="remoteMethod" @change="getAgentName" :loading="loading">
					<el-option v-for="item in options" :label="item.agentNo" :key="item.agentNo" :value="item.agentNo">
						<span>{{item.userName}}({{item.agentNo}})</span>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="入库对象">
				{{ saveData.agentName }}
			</el-form-item>
			<el-form-item label="活动名称">
				<el-form-item prop="activityNo">
					<el-select v-model="saveData.activityNo" @change="change" placeholder="请选择活动名称"
						style="width: 240px;" @focus="policyChange">
						<!-- v-if="item" -->
						<el-option v-for="(item, index) in activetyList" :key="index" :label="item.activityName"
							:value="item.activityNo"></el-option>
					</el-select>
				</el-form-item>
			</el-form-item>
			<el-form-item label="入库时间">
				<el-date-picker v-model="saveData.createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
					style="width: 240px" start-placeholder="入库时间" />
			</el-form-item>
		</el-form>
		<el-row style="padding-left:160px; padding-bottom: 30px;">
			<el-button type="primary" @click="submitForm" :loading="loading">提交</el-button>
			<!-- <el-button type="primary" @click="cancel">取消</el-button> -->
		</el-row>
	</div>
</template>

<script>
import { TerminalApi, AgentApi, PolicyApi } from "@/api";
import FileUpload from "@/components/FileUpload";
export default {
	name: "CustomForm",
	props: {
		type: {
			type: String,
			default: "insert"
		}
	},
	components: {
		FileUpload
	},
	data() {
		return {
			title: "",
			saveData: {
				hardwareNo: "",
				agentNo: "",
				policyNo: "",
				activityNo: ""
			},
			loading: false,
			hardwareList: [],
			options: "",
			policyList: [],
			policies: [],
			activetyList: [],
			activities: [],
			radio: "",
			rules: {
				hardwareNo: [
					{
						required: true,
						message: "请选择机具型号",
						trigger: "change"
					}
				],
				sn: [
					{
						required: true,
						message: "请提交SN列表",
						trigger: "change"
					}
				],
				agentNo: [
					{
						required: true,
						message: "请选择代理/机构编号",
						trigger: "change"
					}
				],
				policyNo: [
					{
						required: true,
						message: "请选择机具政策",
						trigger: "change"
					}
				]
				// activityNo:[{ required: true, message: '请选择活动', trigger: 'change' }]
			},
			channelNo: ''
		};
	},
	watch: {},
	mounted() {

	},
	async created() {
		await this.listHardwares();
		await this.listPolicies(); 

		if (this.$route.query.item) {
			let data = JSON.parse(this.$route.query.item);
			this.channelNo=data.channelNo;
			this.saveData = data;
			this.saveData.createTime = "";
			await this.filterPolicies();
			await this.listActivities(); 
		}  

	},
	methods: {
		change() {
			this.$forceUpdate();
		},
		//根据渠道过滤费率政策
		async filterPolicies() {
			const policies = this.policies.filter((val) => val.channelNo == this.channelNo);
			this.policyList = policies;
		},
		async listHardwares() {
			let result = await TerminalApi.hardwareInfo.getHardwareList();
			if (result.success) this.hardwareList = result.data;
		},
		//获取政策列表
		async listPolicies() {
			let res = await PolicyApi.policyInfo.getPolicyList();
			if (res.success) {
				this.policies = res.data;
			}
		},
		async remoteMethod(query) {
			if (query != "") {
				let res = await AgentApi.queryAllAgentNameList(query);
				if (res.success) {
					this.options = res.data;
				}
			} else {
				this.options = [];
			}
		},
		
		// 绑定活动政策
		async listActivities() {
			if(!this.saveData.agentNo) return;
			let res = await TerminalApi.terminalInfo.getActivityName(this.saveData.agentNo);
			this.activities = res.data;
			let temp=[];
			res.data.forEach((item) => {
				const existed = this.policyList.find(element => element.policyNo == item.policyNo);
				if (existed) {
					temp.push(item);
				}
			});
			this.activetyList = temp;
		},
		submitForm() {
			if (
				this.type == "insert" &&
				!this.saveData.snFileUrl &&
				(!this.saveData.beginSn || !this.saveData.endSn)
			) {
				this.Message.error("请输入连续SN号或者上传SN号文件");
				return;
			}
			this.$refs.saveOrEditForm.validate(valid => {
				if (valid) {
					this.loading = true;
					if (this.type == "insert") {
						TerminalApi.terminalInfo
							.save(this.saveData)
							.then(res => {
								if (res.success) {
									this.Message.success(res.message);
									this.$router.push({
										name: "terminalQuery"
									});
								}
								this.loading = false;
							});
					} else if (this.type == "update") {
						TerminalApi.terminalInfo
							.update(this.saveData)
							.then(res => {
								if (res.success) {
									this.Message.success(res.message);
									this.$router.push({
										name: "terminalQuery"
									});
								}
								this.loading = false;
							});
					} else if (this.type == "batchUpdate") {
						TerminalApi.terminalInfo
							.batchUpdate(this.saveData)
							.then(res => {
								if (res.success) {
									this.Message.success(res.message);
									this.$router.push({
										name: "terminalQuery"
									});
								}
								this.loading = false;
							});
					}
				} else {
					return false;
				}
			});
		},
		cancel() {
			this.saveData = {};
			this.commitOnClose();
		},
		commitOnClose() {
			this.$emit("on-close");
		},
		async getAgentName(agentNo) {
			var agentData = this.options.find(item => item.agentNo == agentNo);
			this.saveData.agentNo = agentNo;
			this.saveData.agentName = agentData.userName;
			this.saveData.activityNo = '';
			await this.listActivities(); 
		},
		policyChange(val) {
			if (!this.saveData.agentNo) {
				this.Message.error("请先选择代理/机构编号");
				return;
			}
		},
		getFile(file) {
			this.saveData.snFileUrl = file;
		},
		async hardwareChange(val) {
			this.saveData.activityNo = '';
			for (let i of this.hardwareList) {
				if (i.hardwareNo == val) {
					this.saveData.hardwareType = i.hardwareType;
					this.channelNo = i.channelNo;
					await this.filterPolicies(); 
					await this.listActivities();
				}
			}
		}
	}
};
</script>

<style scoped></style>
